<template>
    <div class="cont-prin">
        <div class="container-data-title">
                <h2>Información General</h2>
        </div>
        <div class="container-infoCompany" v-for="(dataCompany, i) in dataCompany" :key="i + 1">
            <div class="PrincipalCompany-txt">
                <div class="visualizacion-datos">
                    <div class="photoCompany">
                        <img class="rounded-circle"
                            :src="dataUser.photo ? dataUser.photo.link : dataUser.image ? dataUser.image : imageUrl"
                            alt="Imagen de usuario"
                            style="max-width: 60%; aspect-ratio: 1/1; object-fit: cover; object-position: center; border: 2px solid #ccc;"
                            loading="eager">
                    </div>
                    <div class="Principalinfo-txt">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <p style="font-size: 25px; font-weight: bold; margin-top: 5px;">
                                {{ dataCompany.nameCompany }}
                            </p>
                        </div>
                        <hr>
                    </div>
                    <div>
                        <span style="text-transform: capitalize;">
                            {{ dataCompany.businessSector }},
                            {{ dataCompany.federalentityCompany }}
                        </span>
                    </div>
                </div>
            </div>
        <div class="modificar-datos">
            <div style="width: 100%; margin-bottom: 1.5rem;">
                <div class="tarjeta-company">
                    <div class="modificacion mt-8">
                        <div>
                            <div style="display: flex; flex-direction: column;">
                                <div style="display: flex; flex-direction: column;">
                                    <span>
                                        {{ dataCompany.razonSocialCompany }}
                                    </span>
                                    <span>
                                        {{ dataCompany.sitioWeb }}
                                    </span>
                                    <div v-show="dataCompany.fileConstancia && dataCompany.fileConstancia?.link">
                                        <a style="text-decoration: none; display: flex; align-items: center; color:black"
                                            title="Constancia de Situación Fiscal" target="_blank"
                                            :href="dataCompany.fileConstancia?.link">
                                            <box-icon name='file'></box-icon>
                                            <span>Constancia de Situación Fiscal</span>
                                        </a>
                                    </div>
                                    <div style="display: flex; flex-direction: column;">
                                        <span v-if="dataCompany.mode_admin === true">Módulo de Administración: Sí</span>
                                        <span v-else>Módulo Administración: No</span>
                                    </div>
                                    <div style="display: flex; flex-direction: column;">
                                        <span v-if="dataCompany.mode_client === true">Modo Cliente: Sí</span>
                                        <span v-else>Modo Cliente: No</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.enableInput" style="width: 100%;">
                <div class="tarjeta-company">
                    <div style="display: flex; justify-content: space-between; flex-direction: row;">
                        <div>
                            <span style="font-size: 20px; font-weight: bold;">
                                Datos de Contacto
                            </span>
                        </div>
                        <div class="contenedor-icono"
                            style="display: flex; justify-content: center; align-items: center;">
                            <box-icon class="box-icon" style="cursor: pointer;" name='edit' type='solid'
                                v-on:click="editInf()"></box-icon>
                        </div>
                    </div>
                    &nbsp;
                    <div class="modificacion mt-8">
                        <div>
                            <div class="text-justify">
                                {{ dataCompany.responsableCompany }}
                            </div>
                        </div>
                        <div>
                            <div class="text-justify">
                                {{ dataCompany.positionCompany }}
                            </div>
                        </div>
                        <div>
                            <div class="text-justify">
                                {{ dataCompany.emailCompany }}
                            </div>
                        </div>
                        <div>
                            <div class="text-justify">
                                {{ dataCompany.phoneCompany }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="formEditContact shadow p-3 mb-5 bg-body-tertiary rounded" v-else-if="!this.enableInput">
                <div>
                    <p style="font-size: 20px; font-weight: bold;">
                        Información de Contacto
                    </p>
                    <div class="modificacion">
                        <div>
                            <div class="text-justify">
                                <span style="font-weight: bold;">Contacto</span>
                                <div>
                                    <input type="text" class="form-control" id="floatingInput"
                                        placeholder="Ingresa el nombre de contacto"
                                        v-model="dataCompany.responsableCompany" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="text-justify">
                                <b>Posición o Cargo:</b>
                                <div>
                                    <input type="text" class="form-control" id="floatingInput"
                                        placeholder="Ingresa tu posición ó cargo"
                                        v-model="dataCompany.positionCompany" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="text-justify">
                                <b>Email de Contacto:</b>
                                <div style="display: flex; align-items: center;">
                                    <input type="email" class="form-control" id="floatingInput"
                                        placeholder="Ingresa tu email" v-model="dataCompany.emailCompany" />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div class="text-justify">
                                <b>Telefono de Contacto:</b>
                                <div style="display: flex; align-items: center;">
                                    <input type="tel" class="form-control" id="floatingInput"
                                        placeholder="Ingresa tu número celular" v-model="dataCompany.phoneCompany" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="d-grid gap-2 d-md-flex mt-2 mb-2">
                    <button class="btn btn-primary" type="button"
                        v-on:click="updateinformationCompany()">Guardar</button>
                    <button id="change" class="btn btn-danger me-md-2" type="button" v-on:click="editInf()">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { CompanyService } from "@/services";


export default {
    data: () => {
        return {
            dataCompany: {},
            enableInput: true,
            changeContact: "",
            changePosition: "",
            changeEmail: "",
            changePhone: "",
            dataUser: {},
            imageUrl: "/images/profile.jpg",
        };
    },
    mounted() {
        this.dataUser = JSON.parse(localStorage.getItem("user") || null);
        this.idCompany = this.dataUser._id;
        this.idEmpresa = this.dataUser.company._id;
        this.InformationCompany({ _id: this.idCompany });
    },
    methods: {
        async InformationCompany(id) {
            await CompanyService.getCompanyUser(id).then((response) => {
                this.dataCompany = response.data.data;
                console.log(this.dataCompany);
            });
        },
        editInf() {
            this.enableInput == true ? this.enableInput = false : this.enableInput = true;
        },
        updateinformationCompany() {
            this.idEmpresa = this.dataUser.company._id;
            let updateInformacionC = {
                companyId: this.idEmpresa,
                responsableCompany: this.dataCompany.responsableCompany,
                positionCompany: this.dataCompany.positionCompany,
                phoneCompany: this.dataCompany.phoneCompany,
                emailCompany: this.dataCompany.emailCompany,
            }
            CompanyService.updateInformacionCompany(updateInformacionC).then((response) => {
                window.location.href = "/informationCompany";
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
        },
    },
};
</script>

<style land="scss" scoped>
@import "../styles/informationCompany.scss";
</style>